import type { RouterOptions } from '@nuxt/schema';

import { loadConfig } from '~/src/data.ts';

const routerOptions: RouterOptions = {
    routes: async (routes) => {
        if (process.env.TEST) {
            // workaround as there is no way to retrieve the original page routes
            // which get overridden in the nuxt test environment by this router options
            global.originalRoutes = routes;
        }
        // must use loadConfig() instead of useConfig() because routes are resolved
        // before user plugins with enforce: pre are run
        const config = await loadConfig();
        return routes.flatMap((route) => {
            // workaround for dynamic inclusion of nouns subroutes,
            // as translatedPaths does not work because definePageMeta is not scanned in pages:extend hook in Nuxt 3
            if (typeof route.name === 'string' &&
                route.name.startsWith('nouns-') && !route.name.startsWith(`nouns-${config.locale}`)) {
                return [];
            }
            if (route.meta?.translatedPaths) {
                const translatedPaths = route.meta.translatedPaths(config);
                if (translatedPaths.length === 0) {
                    return [];
                }
                if (Array.isArray(translatedPaths)) {
                    return [{ ...route, path: translatedPaths[0], alias: translatedPaths.slice(1) }];
                }
                return Object.entries(translatedPaths).map(([name, translatedRoute]) => {
                    return {
                        ...route,
                        name,
                        path: translatedRoute.paths[0],
                        alias: translatedRoute.paths.slice(1),
                        meta: translatedRoute.meta,
                    };
                });
            }
            return [route];
        });
    },
};
export default routerOptions;
