import type { CookieOptions } from '#app/composables/cookie';

export const sessionCookieSetting: CookieOptions & { readonly?: false } = {
    sameSite: 'lax',
};

const inAYear = new Date();
inAYear.setFullYear(inAYear.getFullYear() + 1);

export const longtimeCookieSetting: CookieOptions & { readonly?: false } = {
    ...sessionCookieSetting,
    // secure: import.meta.env?.APP_ENV !== 'development',
    expires: inAYear,
};
