import { defineNuxtPlugin } from 'nuxt/app';
import type { Router } from 'vue-router';

declare global {
    interface Window {
        __NUXT_ROUTES__: string[];
    }
}

export default defineNuxtPlugin((nuxtApp) => {
    // Only expose routes in development
    if (import.meta.env?.APP_ENV !== 'production') {
        const router = nuxtApp.$router as Router;
        const routes: string[] = [];

        for (const route of router.getRoutes()) {
            routes.push(route.path);
        }

        window.__NUXT_ROUTES__ = routes;
    }
});
