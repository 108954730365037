export default defineNuxtPlugin(() => {
    useHead({
        script: [
            {
                type: 'speculationrules',
                innerHTML: JSON.stringify({
                    prerender: [
                        {
                            where: {
                                and: [
                                    { href_matches: '/*' },
                                    { not: { selector_matches: '.no-prerender' } },
                                    { not: { selector_matches: '[rel~=nofollow]' } },
                                ],
                            },
                        },
                    ],
                    eagerness: 'moderate',
                }),
            },
        ],
    });
});
