interface Ad {
    enabled: boolean;
    locale?: string | null;
    image: string;
    placeholders: string[];
    link: string;
    display: string;
    alt: string;
    chance?: number;
}

const SHOP_LINK = 'https://shop.pronouns.page';
const SHOP_DESC = 'Pronouns Page merch is here! Get yours at shop.pronouns.page or Etsy!';
const WORKSHOPS_PL_LINK = 'https://zaimki.pl/szkolenia';
const WORKSHOPS_PL_DESC = 'Oferujemy szkolenia i warsztaty dla firm i organizacji';
const WORKSHOPS_EN_LINK = 'https://en.pronouns.page/workshops';
const WORKSHOPS_EN_DESC = 'We offer training and workshops for companies and organisations.';
const PHS_LINK = 'https://polishheritage.shop/?utm_source=pp&utm_medium=banner&utm_campaign=launch';
const PHS_DESC = 'Polish Heritage Shop: Taste of Polish culture, humor and tradition.';

export const adsInternal: Ad[] = [
    {
        enabled: false,
        locale: 'en',
        image: 'shop-vt.png',
        placeholders: ['aside-left-middle'],
        link: SHOP_LINK,
        display: 'd-none d-md-block',
        alt: SHOP_DESC,
    },
    {
        enabled: false,
        locale: 'en',
        image: 'shop-hz.png',
        placeholders: ['aside-right-top'],
        link: SHOP_LINK,
        display: 'd-block d-lg-none',
        alt: SHOP_DESC,
    },
    {
        enabled: true,
        locale: 'pl',
        image: 'workshops-pl.png',
        placeholders: ['content-0'],
        link: WORKSHOPS_PL_LINK,
        display: 'd-none d-md-block',
        alt: WORKSHOPS_PL_DESC,
    },
    {
        enabled: true,
        locale: 'pl',
        image: 'workshops-pl-mobile.png',
        placeholders: ['content-mobile-0'],
        link: WORKSHOPS_PL_LINK,
        display: 'd-block d-md-none',
        alt: WORKSHOPS_PL_DESC,
    },
    {
        enabled: false,
        locale: 'en',
        image: 'workshops-en.png',
        placeholders: ['content-0'],
        link: WORKSHOPS_EN_LINK,
        display: 'd-none d-md-block',
        alt: WORKSHOPS_EN_DESC,
    },
    {
        enabled: false,
        locale: 'en',
        image: 'workshops-en-mobile.png',
        placeholders: ['content-mobile-0'],
        link: WORKSHOPS_EN_LINK,
        display: 'd-block d-md-none',
        alt: WORKSHOPS_EN_DESC,
    },
    {
        enabled: true,
        locale: 'en',
        image: 'phs-1.png',
        placeholders: ['content-0'],
        link: PHS_LINK,
        display: 'd-none d-md-block',
        alt: PHS_DESC,
    },
    {
        enabled: true,
        locale: 'en',
        image: 'phs-2.png',
        placeholders: ['content-0'],
        link: PHS_LINK,
        display: 'd-none d-md-block',
        alt: PHS_DESC,
    },
    {
        enabled: true,
        locale: 'en',
        image: 'phs-mobile.png',
        placeholders: ['content-mobile-0'],
        link: PHS_LINK,
        display: 'd-block d-md-none',
        alt: PHS_DESC,
    },
];

export default (locale: string): Record<string, (Ad & { chance: number })[]> => {
    const adsInternalPerPlaceholder: Record<string, (Ad & { chance: number })[]> = {};
    for (const ad of adsInternal) {
        const rightLocale = ad.locale === null || ad.locale === locale;
        if (!ad.enabled || !rightLocale) {
            continue;
        }

        for (const placeholder of ad.placeholders) {
            if (!Object.hasOwn(adsInternalPerPlaceholder, placeholder)) {
                adsInternalPerPlaceholder[placeholder] = [];
            }
            adsInternalPerPlaceholder[placeholder].push({ chance: 0.25, ...ad });
        }
    }

    return adsInternalPerPlaceholder;
};
