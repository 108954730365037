import { defineNuxtPlugin } from 'nuxt/app';

export default defineNuxtPlugin(() => {
    if (!Object.prototype.hasOwnProperty.call(Object, 'hasOwn')) {
        Object.defineProperty(Object, 'hasOwn', {
            value: (object: object, property: PropertyKey) => {
                return Object.prototype.hasOwnProperty.call(object, property);
            },
        });
    }
    if (!Object.hasOwn(window, 'BroadcastChannel')) {
        Object.defineProperty(window, 'BroadcastChannel', {
            value: class {
                postMessage() {}

                close() {}
            },
        });
    }
});
