import { parsePronouns, parsePronounGroups } from './buildPronoun.ts';
import { Example, NounTemplate, PronounLibrary, Person, NounDeclension } from './classes.ts';
import { buildDict, buildList } from './helpers.ts';

import type { Config } from '~/locale/config.ts';
import type { PronounExamplesData } from '~/locale/data.ts';
import type { Translations } from '~/locale/translations.ts';
import { buildCalendar } from '~/src/calendar/calendar.ts';
import { getLocaleForUrl, getUrlForLocale } from '~/src/domain.ts';

export const getLocale = () => {
    return getLocaleForUrl(useRequestURL()) ?? '_';
};

export const loadConfig = async (locale?: string): Promise<Config> => {
    return (await import(`~/locale/${locale ?? getLocale()}/config.suml`)).default;
};

export const loadTranslations = async (locale?: string): Promise<Translations> => {
    return (await import(`~/locale/${locale ?? getLocale()}/translations.suml`)).default;
};

export const loadPronouns = async (config: Config) => {
    return parsePronouns(config, (await import(`~/locale/${getLocale()}/pronouns/pronouns.tsv`)).default);
};

export const loadPronounGroups = async () => {
    return parsePronounGroups((await import(`~/locale/${getLocale()}/pronouns/pronounGroups.tsv`)).default);
};

export const loadPronounExamples = async () => {
    const examplesRaw = (await import(`~/locale/${getLocale()}/pronouns/examples.tsv`)).default as PronounExamplesData[];
    return buildList(function* () {
        for (const e of examplesRaw) {
            yield new Example(
                Example.parse(e.singular),
                Example.parse(e.plural || e.singular),
                Example.parse(e.null || e.singular),
                Example.parse(e.null_plural || e.plural || e.singular),
                e.isHonorific,
                e.categories
                    ? e.categories.split(',').map((c) => c.trim())
                            .filter((c) => c)
                    : [],
            );
        }
    });
};

export const loadPronounLibrary = async (config: Config) => {
    const [pronouns, pronounGroups] = await Promise.all([loadPronouns(config), loadPronounGroups()]);
    return new PronounLibrary(config, pronounGroups, pronouns);
};

export const loadNounTemplates = async () => {
    const nounTemplatesRaw = (await import(`~/locale/${getLocale()}/nouns/nounTemplates.tsv`)).default;
    return buildList(function* () {
        for (const t of nounTemplatesRaw) {
            yield NounTemplate.from(t);
        }
    });
};

export const loadNounDeclensionTemplates = async () => {
    const nounDeclensionTemplatesRaw = (await import(`~/locale/${getLocale()}/nouns/nounDeclension.tsv`)).default;
    return buildList(function* () {
        for (const d of nounDeclensionTemplatesRaw) {
            yield new NounDeclension(d);
        }
    });
};

export const loadNounAbbreviations = async () => {
    const abbreviationsRaw = (await import(`~/locale/${getLocale()}/nouns/abbr.tsv`)).default;
    return buildDict(function* () {
        for (const a of abbreviationsRaw) {
            yield [a.abbreviation, a.meaning];
        }
    });
};

export const loadNounCases = async () => {
    try {
        return (await import(`~/locale/${getLocale()}/nouns/cases.ts`)).default;
    } catch (error) {
        return {};
    }
};

export const loadPeople = async () => {
    const peopleRaw = (await import(`~/locale/${getLocale()}/people/people.tsv`)).default;
    return buildList(function* () {
        for (const p of peopleRaw) {
            yield new Person(
                p.name,
                p.description,
                p.pronouns.split(','),
                p.sources ? p.sources.split(',') : [],
            );
        }
    });
};

export const loadNameCount = async () => {
    try {
        return (await import(`~/locale/${getLocale()}/names/nameCount.ts`)).default;
    } catch (error) {
        return {};
    }
};

export const loadCalendar = async () => {
    const locale = getLocale();
    const baseUrl = getUrlForLocale(locale);
    const localEvents = (await import(`~/locale/${locale}/calendar/events.ts`)).default;
    /* const rawNamedays = (await import(`~/locale/${config.locale}/names/namedays.json`)).default;
    const namedays = Object.entries(rawNamedays as Record<string, string[]>)
        .flatMap(([name, namedays]) => {
            return namedays.map((nameday) => {
                const [m, d] = nameday.split('-');
                return new Event(`nameday$${name}`, null, parseInt(m), day(parseInt(d)), EventLevel.Nameday);
            });
        }); */
    return buildCalendar(localEvents, baseUrl);
};
