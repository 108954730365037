import { DateTime, Settings } from 'luxon';

import baseTranslations from '~/locale/_base/translations.suml';
import buildLocaleList from '~/src/buildLocaleList.ts';
import type { Pronoun } from '~/src/classes.ts';
import { loadConfig, loadTranslations } from '~/src/data.ts';
import { buildDict } from '~/src/helpers.ts';
import { Translator } from '~/src/translator.ts';

declare module '#app' {
    interface NuxtApp {
        $t<T extends string | string[] = string>
        (key: string, params?: Record<string, string | number>, warn?: boolean): NoInfer<T>;
    }
}

declare module 'vue' {
    interface ComponentCustomProperties {
        $t<T extends string | string[] = string>
        (key: string, params?: Record<string, string | number>, warn?: boolean): NoInfer<T>;
    }
}

export default defineNuxtPlugin({
    enforce: 'pre',
    async setup() {
        const [config, translations] = await Promise.all([loadConfig(), loadTranslations()]);

        const translator = new Translator(translations, baseTranslations, config);
        const t = (key: string, params = {}, warn = false): string => translator.translate(key, params, warn);
        const te = (key: string, fallback = false): boolean => {
            if (translator.has(key)) {
                return true;
            }
            if (fallback && translator.hasFallback(key)) {
                return true;
            }
            return false;
        };
        const translateForPronoun = (str: string, pronoun: Pronoun | null): string => {
            let translation = translator.translate(`flags.${str.replace(/ /g, '_').replace(/'/g, '*')}`, {}, false) || str;
            if (pronoun) {
                translation = pronoun.format(translation);
            }
            return translation;
        };

        const locales = buildDict(function* () {
            const locales = buildLocaleList(config.locale);
            if (config.locale !== '_') {
                yield [config.locale, locales[config.locale]];
            }
            for (const [locale, localeDescription] of Object.entries(locales)) {
                if (locale !== config.locale) {
                    yield [locale, localeDescription];
                }
            }
        });

        try {
            Settings.defaultLocale = config.intlLocale || config.locale;
            DateTime.now().toFormat('y-MM-dd HH:mm'); // test if locale is supported by luxon
        } catch {
            Settings.defaultLocale = 'en';
        }

        return {
            provide: {
                // identifier must be different to config because it is already reserved for Nuxt runtime config
                localeConfig: config,
                translator,
                t,
                te,
                translateForPronoun,
                locales,
            },
        };
    },
});
