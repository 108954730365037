import routerOptions0 from "/builds/PronounsPage/PronounsPage/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_sqlite3@5.1.7__encodin_28798345c8b4ce724a6abbd11cc11242/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/builds/PronounsPage/PronounsPage/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}