import localeDescriptions from '~/locale/locales.ts';
import buildLocaleList from '~/src/buildLocaleList.ts';

export const getLocaleUrls = (domainBase: string | undefined): Record<string, string> => {
    if (domainBase) {
        return Object.fromEntries([
            ['_', domainBase.replace('{locale}', '')
                .replace('/.', '/')
                .replace('-.', '.')],
            ['no', domainBase.replace('{locale}', 'no')],
            ...localeDescriptions.map((localeDescription) => {
                return [localeDescription.code, domainBase.replace('{locale}', localeDescription.code)];
            }),
        ]);
    }
    return Object.fromEntries([
        ['_', 'https://pronouns.page'],
        ['no', 'https://no.pronouns.page'],
        ...localeDescriptions.map((localeDescription) => {
            return [localeDescription.code, localeDescription.url];
        }),
    ]);
};

export const getUrlForLocale = (locale: string, domainBase: string | undefined = undefined) => {
    return getLocaleUrls(domainBase ?? useRuntimeConfig().public.domainBase)[locale];
};

export const getUrlsForAllLocales = (
    locale: string,
    includeUnpublished: boolean = false,
    domainBase: string | undefined = undefined,
) => {
    const locales = buildLocaleList(locale, includeUnpublished);
    const urls = getLocaleUrls(domainBase ?? useRuntimeConfig().public.domainBase);

    const codes = ['_', ...Object.values(locales).map((localeDescription) => localeDescription.code)];
    return codes.map((code) => urls[code]);
};

export const getLocaleForUrl = (url: URL): string | undefined => {
    const match = Object.entries(getLocaleUrls(useRuntimeConfig().public.domainBase))
        .find(([_, origin]) => url.origin === origin);
    if (!match) {
        return undefined;
    }
    return match[0];
};
