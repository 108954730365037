import payload_plugin_8c8nYguRwPNy8yajHUKd1Z_Ydx4ZQ2lxYHAaiuh6B2s from "/builds/PronounsPage/PronounsPage/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.2_vue@3.5.13_typescript@5.7.2__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_TvNV_PDBdTDBtqVfGxKJBqxN6vT4e9O1naF_u3NhEDU from "/builds/PronounsPage/PronounsPage/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_sqlite3@5.1.7__encodin_28798345c8b4ce724a6abbd11cc11242/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_PkSs8laZ5wtarA7KxkDlGRi5FOcbUKQdyiML6qzRd9U from "/builds/PronounsPage/PronounsPage/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_sqlite3@5.1.7__encodin_28798345c8b4ce724a6abbd11cc11242/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_BVx94IIW7Ob0Gdd2FNwEXTkmxXjf7O27Qo8haO6mI5g from "/builds/PronounsPage/PronounsPage/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_sqlite3@5.1.7__encodin_28798345c8b4ce724a6abbd11cc11242/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import locale_byMupTEZHnzG0dFOwhH5C1lEhO5_yZVFxGnDNAQ4WG0 from "/builds/PronounsPage/PronounsPage/plugins/locale.ts";
import payload_client_zqwGFJan9FTGayk6E2zAHAPIMBSJ47O4AxSaT3HUaVk from "/builds/PronounsPage/PronounsPage/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_sqlite3@5.1.7__encodin_28798345c8b4ce724a6abbd11cc11242/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_HOJJka2TEqJTU_PCBxcPgbPBUso2xvigjy88r_i5pvE from "/builds/PronounsPage/PronounsPage/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_sqlite3@5.1.7__encodin_28798345c8b4ce724a6abbd11cc11242/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_IEliEsdrFnDTvNZtJ5TPJRlEfSzyeCkUjIE6TVmAtME from "/builds/PronounsPage/PronounsPage/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_sqlite3@5.1.7__encodin_28798345c8b4ce724a6abbd11cc11242/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_3pFdwGJ0upPGYLZq_xnW8d6nMmo06L8abyRdBGuxF8Y from "/builds/PronounsPage/PronounsPage/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_sqlite3@5.1.7__encodin_28798345c8b4ce724a6abbd11cc11242/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_aYr_9E1P8yl_GZWsjGBP00xsbziNEMXUI5nizR_PkP0 from "/builds/PronounsPage/PronounsPage/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.2_vue@3.5.13_typescript@5.7.2__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/builds/PronounsPage/PronounsPage/.nuxt/components.plugin.mjs";
import prefetch_client_Lt_U_oo96ElIbdzBHeUziwsrQK_6QfJpL2TsJN_oH90 from "/builds/PronounsPage/PronounsPage/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_sqlite3@5.1.7__encodin_28798345c8b4ce724a6abbd11cc11242/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg from "/builds/PronounsPage/PronounsPage/.nuxt/pwa-icons-plugin.ts";
import pwa_client_8Dr66J_UkhoUof9NM5Mt_w_xLxf2BM2m71PzR3WzoZ0 from "/builds/PronounsPage/PronounsPage/node_modules/.pnpm/@vite-pwa+nuxt@0.10.6_magicast@0.3.5_vite@6.2.2_@types+node@22.13.14_jiti@2.4.2_sass@1._c841ce524e81b95683b6c3532b269aa8/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_client_0DSVlk_DWJIsXiY4Q2rzCCZ9XNjn36aQ0XNJc69R_bc from "/builds/PronounsPage/PronounsPage/node_modules/.pnpm/@nuxtjs+plausible@1.2.0_magicast@0.3.5/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import auth_ydS_uAQ5qS4E2Ez9vBX0d0JPoo00WYZNOgh9_nraCUo from "/builds/PronounsPage/PronounsPage/plugins/auth.ts";
import browserDetect_BdEvBhUbdxrasalbt65ECirTdOZIIEi1Si8_HME4ttA from "/builds/PronounsPage/PronounsPage/plugins/browserDetect.ts";
import exposeRoutes_client_lLD04D05EZioHAotFGRvrPfEad9lQ32lxBEOrW4XamE from "/builds/PronounsPage/PronounsPage/plugins/exposeRoutes.client.ts";
import globals_l0LLwvMn5OXIJiW8MvIXaLXJNj9B5qMK_SI88d520HI from "/builds/PronounsPage/PronounsPage/plugins/globals.ts";
import gtag_FqQCaoJfNIz6cw2NPKUm7p5XzVWckT2UREryyiEQ_Do from "/builds/PronounsPage/PronounsPage/plugins/gtag.ts";
import polyfill_client_5fImxDeGLOOxL_JKx2T3fIXc5Na7wT_i4J36oc3X_kE from "/builds/PronounsPage/PronounsPage/plugins/polyfill.client.ts";
import sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE from "/builds/PronounsPage/PronounsPage/plugins/sentry.client.ts";
import speculationrules_7GcPXDQ41lhoCidrZDSBOtoacZwZubRtpARNf85ZMkY from "/builds/PronounsPage/PronounsPage/plugins/speculationrules.ts";
import track_client_ysxGi8ZmHF5j06aJCTd3Qm3TtEGbzotHO8NxeiKQeFE from "/builds/PronounsPage/PronounsPage/plugins/track.client.ts";
import plugin_auto_pageviews_client_jObAEHGbMiFxvkHYEDQUtr3ztxD1c5DoRbsB1cWdRVs from "/builds/PronounsPage/PronounsPage/node_modules/.pnpm/@nuxtjs+plausible@1.2.0_magicast@0.3.5/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
export default [
  payload_plugin_8c8nYguRwPNy8yajHUKd1Z_Ydx4ZQ2lxYHAaiuh6B2s,
  revive_payload_client_TvNV_PDBdTDBtqVfGxKJBqxN6vT4e9O1naF_u3NhEDU,
  unhead_PkSs8laZ5wtarA7KxkDlGRi5FOcbUKQdyiML6qzRd9U,
  router_BVx94IIW7Ob0Gdd2FNwEXTkmxXjf7O27Qo8haO6mI5g,
  locale_byMupTEZHnzG0dFOwhH5C1lEhO5_yZVFxGnDNAQ4WG0,
  payload_client_zqwGFJan9FTGayk6E2zAHAPIMBSJ47O4AxSaT3HUaVk,
  navigation_repaint_client_HOJJka2TEqJTU_PCBxcPgbPBUso2xvigjy88r_i5pvE,
  check_outdated_build_client_IEliEsdrFnDTvNZtJ5TPJRlEfSzyeCkUjIE6TVmAtME,
  chunk_reload_client_3pFdwGJ0upPGYLZq_xnW8d6nMmo06L8abyRdBGuxF8Y,
  plugin_vue3_aYr_9E1P8yl_GZWsjGBP00xsbziNEMXUI5nizR_PkP0,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_Lt_U_oo96ElIbdzBHeUziwsrQK_6QfJpL2TsJN_oH90,
  pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg,
  pwa_client_8Dr66J_UkhoUof9NM5Mt_w_xLxf2BM2m71PzR3WzoZ0,
  plugin_client_0DSVlk_DWJIsXiY4Q2rzCCZ9XNjn36aQ0XNJc69R_bc,
  auth_ydS_uAQ5qS4E2Ez9vBX0d0JPoo00WYZNOgh9_nraCUo,
  browserDetect_BdEvBhUbdxrasalbt65ECirTdOZIIEi1Si8_HME4ttA,
  exposeRoutes_client_lLD04D05EZioHAotFGRvrPfEad9lQ32lxBEOrW4XamE,
  globals_l0LLwvMn5OXIJiW8MvIXaLXJNj9B5qMK_SI88d520HI,
  gtag_FqQCaoJfNIz6cw2NPKUm7p5XzVWckT2UREryyiEQ_Do,
  polyfill_client_5fImxDeGLOOxL_JKx2T3fIXc5Na7wT_i4J36oc3X_kE,
  sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE,
  speculationrules_7GcPXDQ41lhoCidrZDSBOtoacZwZubRtpARNf85ZMkY,
  track_client_ysxGi8ZmHF5j06aJCTd3Qm3TtEGbzotHO8NxeiKQeFE,
  plugin_auto_pageviews_client_jObAEHGbMiFxvkHYEDQUtr3ztxD1c5DoRbsB1cWdRVs
]