export default defineNuxtPlugin(() => {
    const config = useConfig();
    if (!config.ads?.enabled || import.meta.env?.APP_ENV !== 'production') {
        return;
    }

    useHead({
        script: [
            {
                src: 'https://www.googletagmanager.com/gtag/js?id=G-TDJEP12Q3M',
                async: true,
            },
            {
                textContent: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-TDJEP12Q3M');
                `,
                type: 'text/javascript',
            },
        ],
    });
});
