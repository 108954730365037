import validate from "/builds/PronounsPage/PronounsPage/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_sqlite3@5.1.7__encodin_28798345c8b4ce724a6abbd11cc11242/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45global from "/builds/PronounsPage/PronounsPage/middleware/redirect.global.ts";
import manifest_45route_45rule from "/builds/PronounsPage/PronounsPage/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_sqlite3@5.1.7__encodin_28798345c8b4ce724a6abbd11cc11242/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "layout-from-query": () => import("/builds/PronounsPage/PronounsPage/middleware/layoutFromQuery.ts")
}