<script setup lang="ts">
import useDialogue from '~/composables/useDialogue.ts';
import { sessionCookieSetting } from '~/src/cookieSettings.ts';
import { useMainStore } from '~/store/index.ts';

const props = defineProps<{
    params?: Record<string, string | number>;
    icon?: string;
}>();

const { $translator: translator } = useNuxtApp();

const translationsCookie = useCookie('translations', sessionCookieSetting);

const store = useMainStore();

const slots = defineSlots<{
    default(): VNode[];
}>();

const key = computed(() => {
    return (slots.default()[0].children as string || '').trim();
});
const modified = computed(() => {
    return store.translationMode && Object.hasOwn(store.translationChanges, key.value);
});
const txt = computed(() => {
    return modified.value
        ? translator.applyParams(store.translationChanges[key.value], props.params || {})
        : translator.translate(key.value, props.params || {});
});
const classes = computed(() => {
    return [store.translationMode ? 't-translation-mode' : '', modified.value ? 't-modified' : ''];
});

const dialogue = useDialogue();
const clicked = async (e: MouseEvent) => {
    if (!store.translationMode) {
        return;
    }

    e.preventDefault();
    e.stopPropagation();

    const base = translator.get(key.value, false, true);

    const newValue = await dialogue.editor(
        modified.value
            ? store.translationChanges[key.value]
            : translator.get(key.value),
        {
            icon: 'language',
            header: key.value,
            message: base
                ? `<div class="small alert alert-info">${
                    Array.isArray(base)
                        ? `<ul>${base.map((el) => `<li>${el}</el>`)}</ul>`
                        : base
                }</div>`
                : undefined,
            margin: false,
        },
        'info',
    );

    if (newValue !== undefined) {
        store.translate({ translator, key: key.value, newValue });
        translationsCookie.value = store.translationChanges;
    }
};
</script>

<template>
    <template v-if="Array.isArray(txt)">
        <div :class="classes" v-bind="$attrs" @click="clicked">
            <p v-for="(paragraph, i) in txt" :key="i">
                <Icon v-if="icon" :v="icon" /><LinkedText :text="paragraph" />
            </p>
        </div>
    </template>
    <template v-else>
        <Icon v-if="icon" :v="icon" /><LinkedText v-bind="$attrs" :text="txt" :class="classes" @click="clicked" />
    </template>
</template>

<style lang="scss">
@import "assets/variables";

.t-translation-mode {
    border: 1px solid rgba($primary, 50%);
    cursor: alias;
    &:hover {
        border: 1px solid $primary;
    }
}

.t-modified {
    background-color: rgba($primary, 30%);
}
</style>
