
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as abuseReportsaYQTISqINtIJH0RRSxiz4jxzdISAYk9kYS59UuGda70Meta } from "/builds/PronounsPage/PronounsPage/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93G_8uwRnYeMNq52TYvpHbYvp_Ijdf18X64PPvm59YEoEMeta } from "/builds/PronounsPage/PronounsPage/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as editor0dIp0oOXrlWAxOmRmcUbhzR66bcg_CcNqiOxrUwJ7zIMeta } from "/builds/PronounsPage/PronounsPage/pages/admin/blog/editor.vue?macro=true";
import { default as index84s9V3J49x_45G7MhnHG6zVGR0gnLGHWCKKyKDzO_iqqQMeta } from "/builds/PronounsPage/PronounsPage/pages/admin/index.vue?macro=true";
import { default as moderationyNvvu1_45g1toa36GLLJ73KziNUAZH7X7ZEQk5yIVQ2l0Meta } from "/builds/PronounsPage/PronounsPage/pages/admin/moderation.vue?macro=true";
import { default as pendingBansOr97eMzx3r5W3NIFrdzg0HkCu5e5PNAeH2J6J_EDWvMMeta } from "/builds/PronounsPage/PronounsPage/pages/admin/pendingBans.vue?macro=true";
import { default as profilesFD_45cpl_45VUR9AhcrItnlXUU4_UcLW2UNF8XzpqDBO8cIMeta } from "/builds/PronounsPage/PronounsPage/pages/admin/profiles.vue?macro=true";
import { default as storageHkSJkTe3n4WkNlfoV25dkTB1uCwtg8eJvEYYFKIWLwYMeta } from "/builds/PronounsPage/PronounsPage/pages/admin/storage.vue?macro=true";
import { default as expensesyTLt95NSWipFWlye3RXVIYzF2BUtpvWkc_oV8yr8s5kMeta } from "/builds/PronounsPage/PronounsPage/pages/admin/timesheets/expenses.vue?macro=true";
import { default as indexvOjfvYi8HypATXaYIrSvHyKtz0GwUGnVQ3yykfnx0yYMeta } from "/builds/PronounsPage/PronounsPage/pages/admin/timesheets/index.vue?macro=true";
import { default as overviewWQ6c7xi4x2m2tamBf_45sZ_HkUwE4iJyU_45pzIu9KJL9MoMeta } from "/builds/PronounsPage/PronounsPage/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingeZl1xziL98D1YMn9CV9GFgwUNRq3vbFbZhzNYjw7r6AMeta } from "/builds/PronounsPage/PronounsPage/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingFA4IQskOnV_45DndNEOH2MwEuD3XnuV9anQNCISywJ2NsMeta } from "/builds/PronounsPage/PronounsPage/pages/admin/translations/missing.vue?macro=true";
import { default as usersT936qPc6VRqLIfGgtgZVqMnW3RXnPtxk5Z2nWImrQk0Meta } from "/builds/PronounsPage/PronounsPage/pages/admin/users.vue?macro=true";
import { default as api8YLuSZ1aNg125hR6zEF4WLLos0EfTD9mROpsb65hlfsMeta } from "/builds/PronounsPage/PronounsPage/pages/api.vue?macro=true";
import { default as _91slug_93_45rE2CHEqdMmtPc_AD9_45MzyaPA3ZoV5kg_660sw68tDwMeta } from "/builds/PronounsPage/PronounsPage/pages/blog/[slug].vue?macro=true";
import { default as indexP00vhz8CaN1uyqB_45EUebt8YdLuagcOPM_4527F02S_45oBAMeta } from "/builds/PronounsPage/PronounsPage/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_93XqpCiqiaQnVOSK6jhYSQf03AFOz_451KexnFTAyJ9eesAMeta } from "/builds/PronounsPage/PronounsPage/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_93UC2zmiflstmKpYCqMSlISPqRlb1jz6QkQw9GFTSt6oMMeta } from "/builds/PronounsPage/PronounsPage/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as admin_zYYVEjpZorrMJiFTCOlar85kZicNpG4n4TZEgsRf78Meta } from "/builds/PronounsPage/PronounsPage/pages/census/admin.vue?macro=true";
import { default as indexl7Im7hpr2pHcK9OcCIYCd6Y9ZBx2cB4IjluYaytrw2AMeta } from "/builds/PronounsPage/PronounsPage/pages/census/index.vue?macro=true";
import { default as contact21rteKW3nsYzPWyKbxrw0EMSd8B1ZJ53NWsxkkS_3M8Meta } from "/builds/PronounsPage/PronounsPage/pages/contact.vue?macro=true";
import { default as designZkRu7zT1_22onsEfnFTZAYoeoABJrUcxHNs2KWclndEMeta } from "/builds/PronounsPage/PronounsPage/pages/design.vue?macro=true";
import { default as english8_45q9_HBXg_45ldKc5hwJXyfjXTiumCF3jaORgffjXCQrYMeta } from "/builds/PronounsPage/PronounsPage/pages/english.vue?macro=true";
import { default as faqP7f39d_45EieZBUOMa_gybAGY6fuaR430lDLUYJGB56i8Meta } from "/builds/PronounsPage/PronounsPage/pages/faq.vue?macro=true";
import { default as inclusiveM8MMFtIm2pJKi7cMomrEvljQ39N7goDuaLZNLYyxt_cMeta } from "/builds/PronounsPage/PronounsPage/pages/inclusive.vue?macro=true";
import { default as index_45homeOrqi3EMSkOoXhY9LWHfXhLXRUJ9Qh5NhL_oUatyZrwgMeta } from "/builds/PronounsPage/PronounsPage/pages/index-home.vue?macro=true";
import { default as index_45localewZzg1GE3zlZR6O5sEP749C2vjDBnkuXOwj_45nbl2A6RwMeta } from "/builds/PronounsPage/PronounsPage/pages/index-locale.vue?macro=true";
import { default as langswitchwBxG2IT6OrWoS5tVU5QSCyYiXzrmglqRXabaxwezeLoMeta } from "/builds/PronounsPage/PronounsPage/pages/langswitch.vue?macro=true";
import { default as licensetCRbDi_jEgQinO0f0vMkxA1xa5d0KVyvxcmqfPXDtc0Meta } from "/builds/PronounsPage/PronounsPage/pages/license.vue?macro=true";
import { default as academicnlRLdh_455SvXcaHfOJaHuWItN_45avOYh15lumZbwv4pPYMeta } from "/builds/PronounsPage/PronounsPage/pages/links/academic.vue?macro=true";
import { default as indexxF0S_45ppI11suRKWtfkO_x6q3_45scyxYV4i3awRtj_45X_UMeta } from "/builds/PronounsPage/PronounsPage/pages/links/index.vue?macro=true";
import { default as mediax6PT7JdXAjuwWLJ3Sz_45dmUpmul6tICDY7uOtUyEC1wYMeta } from "/builds/PronounsPage/PronounsPage/pages/links/media.vue?macro=true";
import { default as translinguisticseSBxB1ji7y8pGs5T3bYnkye7WSwJgdfr3KOCLduF5tUMeta } from "/builds/PronounsPage/PronounsPage/pages/links/translinguistics.vue?macro=true";
import { default as zineAbBXrfEnRkOQBUcoNThpwmlpfI4wIgb0HYeM5pIAE24Meta } from "/builds/PronounsPage/PronounsPage/pages/links/zine.vue?macro=true";
import { default as namesIuf1t8bNByKRVUyKSNlyvUMvocJk5Yy3SYn5M3kxbasMeta } from "/builds/PronounsPage/PronounsPage/pages/names.vue?macro=true";
import { default as indexIoVrZZXNQ4xlB1XA6kSd_50jCySdLOGzNXXR79uj5AAMeta } from "/builds/PronounsPage/PronounsPage/pages/nouns/index.vue?macro=true";
import { default as templates3yj_mthhAxeSRCuiYETip5uC9dJrZDtODqBLDQleKp0Meta } from "/builds/PronounsPage/PronounsPage/pages/nouns/templates.vue?macro=true";
import { default as peopleNacbRKU4ormOz_4516Z7h9Dp7ncmqqDS4gHF8gjOdNB8AMeta } from "/builds/PronounsPage/PronounsPage/pages/people.vue?macro=true";
import { default as privacyh4R6yECArjGsQZG8vja7e26p6PKcYpNLywX_UnDU9xkMeta } from "/builds/PronounsPage/PronounsPage/pages/privacy.vue?macro=true";
import { default as _91username_93n0o90wPVISYZlrhoi_mvm1_452DNhpwyJm60vo_q6wJHYMeta } from "/builds/PronounsPage/PronounsPage/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_93HjWpNFjjJVztmaLIYPnAk1cnm_oJRvjH1SiMqZFHwDcMeta } from "/builds/PronounsPage/PronounsPage/pages/profile/card-[username].vue?macro=true";
import { default as editorL4wdm8X9IKp5RFXXn20owWlSxLpIHFQKW7OdtVkzFG8Meta } from "/builds/PronounsPage/PronounsPage/pages/profile/editor.vue?macro=true";
import { default as anyQHuUiJv_Vq4H6jU3rs2TpPxumJIGUhoimkGxMZk_6uwMeta } from "/builds/PronounsPage/PronounsPage/pages/pronouns/any.vue?macro=true";
import { default as ask1LvXX6IZDbTCNz1lNDXn_vcJXiKj7ZejjhFz22X6_bgMeta } from "/builds/PronounsPage/PronounsPage/pages/pronouns/ask.vue?macro=true";
import { default as avoidingnBxIN9QhCsvL_8pEBUcrUEsvZ1wvmugByLg8PWOEw_4Meta } from "/builds/PronounsPage/PronounsPage/pages/pronouns/avoiding.vue?macro=true";
import { default as indexpd_c0AgNgNVBSbvXz1yGzo4rWXuXSqWHNoykzprI1vUMeta } from "/builds/PronounsPage/PronounsPage/pages/pronouns/index.vue?macro=true";
import { default as mirrorLPL3Wh87MY679A_tGW_96mPb8uVkjtZ9LsFBslaw8CEMeta } from "/builds/PronounsPage/PronounsPage/pages/pronouns/mirror.vue?macro=true";
import { default as pronouniLWoUqS0_TwhpCuYPofgmYnzYZkBo5ckVD8SyojJ2Q0Meta } from "/builds/PronounsPage/PronounsPage/pages/pronouns/pronoun.vue?macro=true";
import { default as sourcesKTqHtdZXbx8SARrDV9xnsF_45iDda6kRDLBxUqRZ7OiQsMeta } from "/builds/PronounsPage/PronounsPage/pages/sources.vue?macro=true";
import { default as teamXxqDf96azkWjbcPp14KW5UwXodvRMLxNtZ8lb5ZmJAIMeta } from "/builds/PronounsPage/PronounsPage/pages/team.vue?macro=true";
import { default as terminology_PkJfiodmVvQJqouvBiQEb2Q_0vunYDSX1mGwwVZgWAMeta } from "/builds/PronounsPage/PronounsPage/pages/terminology.vue?macro=true";
import { default as termsmMuZae6RnJfP_450mcW4mxUai_cHaKwJXDJSJ1N1GmO5UMeta } from "/builds/PronounsPage/PronounsPage/pages/terms.vue?macro=true";
import { default as userzfiAVzVFNmco__tWngTh7J1rPBMcBZZ_3b5letpdDGEMeta } from "/builds/PronounsPage/PronounsPage/pages/user.vue?macro=true";
import { default as workshops0RkkUY6BqUTE221_0adPCnFDeEw36LLyV6osQkpfWIcMeta } from "/builds/PronounsPage/PronounsPage/pages/workshops.vue?macro=true";
import { default as deklinationsmusterBBXyRMHu7fmYzi_45AaiFUzxjRoguPCTPM2LIKb_RlgVAMeta } from "/builds/PronounsPage/PronounsPage/locale/de/nouns/deklinationsmuster.vue?macro=true";
import { default as neutratywyt9JGP0D3Hg70pxPbpOtXI4_45Y_45L3Mfxwg5F_2R8Aq5XsMeta } from "/builds/PronounsPage/PronounsPage/locale/pl/nouns/neutratywy.vue?macro=true";
import { default as dukatywyAOAyOxIUZa0ViI8Q1YDXZP5I6qkHpeOvycD3yCQTqpEMeta } from "/builds/PronounsPage/PronounsPage/locale/pl/nouns/dukatywy.vue?macro=true";
import { default as osobatywywPCC_45CZ73Xc9xLmB6qOEHK9uk5UiJ0orccNBCX_AcYoMeta } from "/builds/PronounsPage/PronounsPage/locale/pl/nouns/osobatywy.vue?macro=true";
import { default as iksatywyZ9x4XbifHkYzleEkr1T0oeRRfuR1rQHvKfqNZDeKLEYMeta } from "/builds/PronounsPage/PronounsPage/locale/pl/nouns/iksatywy.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReportsaYQTISqINtIJH0RRSxiz4jxzdISAYk9kYS59UuGda70Meta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/builds/PronounsPage/PronounsPage/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin-blog-editor",
    path: "/admin/blog/editor",
    component: () => import("/builds/PronounsPage/PronounsPage/pages/admin/blog/editor.vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/builds/PronounsPage/PronounsPage/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/builds/PronounsPage/PronounsPage/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBansOr97eMzx3r5W3NIFrdzg0HkCu5e5PNAeH2J6J_EDWvMMeta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/builds/PronounsPage/PronounsPage/pages/admin/profiles.vue")
  },
  {
    name: "admin-storage",
    path: "/admin/storage",
    component: () => import("/builds/PronounsPage/PronounsPage/pages/admin/storage.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/builds/PronounsPage/PronounsPage/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/builds/PronounsPage/PronounsPage/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/builds/PronounsPage/PronounsPage/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/builds/PronounsPage/PronounsPage/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/builds/PronounsPage/PronounsPage/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/builds/PronounsPage/PronounsPage/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: api8YLuSZ1aNg125hR6zEF4WLLos0EfTD9mROpsb65hlfsMeta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93_45rE2CHEqdMmtPc_AD9_45MzyaPA3ZoV5kg_660sw68tDwMeta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexP00vhz8CaN1uyqB_45EUebt8YdLuagcOPM_4527F02S_45oBAMeta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_93XqpCiqiaQnVOSK6jhYSQf03AFOz_451KexnFTAyJ9eesAMeta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_93UC2zmiflstmKpYCqMSlISPqRlb1jz6QkQw9GFTSt6oMMeta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: admin_zYYVEjpZorrMJiFTCOlar85kZicNpG4n4TZEgsRf78Meta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: indexl7Im7hpr2pHcK9OcCIYCd6Y9ZBx2cB4IjluYaytrw2AMeta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contact21rteKW3nsYzPWyKbxrw0EMSd8B1ZJ53NWsxkkS_3M8Meta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/builds/PronounsPage/PronounsPage/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: english8_45q9_HBXg_45ldKc5hwJXyfjXTiumCF3jaORgffjXCQrYMeta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqP7f39d_45EieZBUOMa_gybAGY6fuaR430lDLUYJGB56i8Meta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusiveM8MMFtIm2pJKi7cMomrEvljQ39N7goDuaLZNLYyxt_cMeta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/inclusive.vue")
  },
  {
    name: "index-home",
    path: "/index-home",
    meta: index_45homeOrqi3EMSkOoXhY9LWHfXhLXRUJ9Qh5NhL_oUatyZrwgMeta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/index-home.vue")
  },
  {
    name: "index",
    path: "/index-locale",
    meta: index_45localewZzg1GE3zlZR6O5sEP749C2vjDBnkuXOwj_45nbl2A6RwMeta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/index-locale.vue")
  },
  {
    name: "langswitch",
    path: "/langswitch",
    meta: langswitchwBxG2IT6OrWoS5tVU5QSCyYiXzrmglqRXabaxwezeLoMeta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/langswitch.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/builds/PronounsPage/PronounsPage/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academicnlRLdh_455SvXcaHfOJaHuWItN_45avOYh15lumZbwv4pPYMeta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: indexxF0S_45ppI11suRKWtfkO_x6q3_45scyxYV4i3awRtj_45X_UMeta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: mediax6PT7JdXAjuwWLJ3Sz_45dmUpmul6tICDY7uOtUyEC1wYMeta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticseSBxB1ji7y8pGs5T3bYnkye7WSwJgdfr3KOCLduF5tUMeta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zineAbBXrfEnRkOQBUcoNThpwmlpfI4wIgb0HYeM5pIAE24Meta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: namesIuf1t8bNByKRVUyKSNlyvUMvocJk5Yy3SYn5M3kxbasMeta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: indexIoVrZZXNQ4xlB1XA6kSd_50jCySdLOGzNXXR79uj5AAMeta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templates3yj_mthhAxeSRCuiYETip5uC9dJrZDtODqBLDQleKp0Meta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: peopleNacbRKU4ormOz_4516Z7h9Dp7ncmqqDS4gHF8gjOdNB8AMeta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyh4R6yECArjGsQZG8vja7e26p6PKcYpNLywX_UnDU9xkMeta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93n0o90wPVISYZlrhoi_mvm1_452DNhpwyJm60vo_q6wJHYMeta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_93HjWpNFjjJVztmaLIYPnAk1cnm_oJRvjH1SiMqZFHwDcMeta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editorL4wdm8X9IKp5RFXXn20owWlSxLpIHFQKW7OdtVkzFG8Meta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anyQHuUiJv_Vq4H6jU3rs2TpPxumJIGUhoimkGxMZk_6uwMeta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-ask",
    path: "/pronouns/ask",
    meta: ask1LvXX6IZDbTCNz1lNDXn_vcJXiKj7ZejjhFz22X6_bgMeta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/pronouns/ask.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoidingnBxIN9QhCsvL_8pEBUcrUEsvZ1wvmugByLg8PWOEw_4Meta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: indexpd_c0AgNgNVBSbvXz1yGzo4rWXuXSqWHNoykzprI1vUMeta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrorLPL3Wh87MY679A_tGW_96mPb8uVkjtZ9LsFBslaw8CEMeta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronouniLWoUqS0_TwhpCuYPofgmYnzYZkBo5ckVD8SyojJ2Q0Meta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sourcesKTqHtdZXbx8SARrDV9xnsF_45iDda6kRDLBxUqRZ7OiQsMeta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: teamXxqDf96azkWjbcPp14KW5UwXodvRMLxNtZ8lb5ZmJAIMeta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminology_PkJfiodmVvQJqouvBiQEb2Q_0vunYDSX1mGwwVZgWAMeta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsmMuZae6RnJfP_450mcW4mxUai_cHaKwJXDJSJ1N1GmO5UMeta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: userzfiAVzVFNmco__tWngTh7J1rPBMcBZZ_3b5letpdDGEMeta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshops0RkkUY6BqUTE221_0adPCnFDeEw36LLyV6osQkpfWIcMeta || {},
    component: () => import("/builds/PronounsPage/PronounsPage/pages/workshops.vue")
  },
  {
    name: "nouns-de-deklinationsmuster",
    path: "/deklinationsmuster",
    component: () => import("/builds/PronounsPage/PronounsPage/locale/de/nouns/deklinationsmuster.vue")
  },
  {
    name: "nouns-pl-neutratywy",
    path: "/neutratywy",
    component: () => import("/builds/PronounsPage/PronounsPage/locale/pl/nouns/neutratywy.vue")
  },
  {
    name: "nouns-pl-dukatywy",
    path: "/dukatywy",
    component: () => import("/builds/PronounsPage/PronounsPage/locale/pl/nouns/dukatywy.vue")
  },
  {
    name: "nouns-pl-osobatywy",
    path: "/osobatywy",
    component: () => import("/builds/PronounsPage/PronounsPage/locale/pl/nouns/osobatywy.vue")
  },
  {
    name: "nouns-pl-iksatywy",
    path: "/iksatywy",
    component: () => import("/builds/PronounsPage/PronounsPage/locale/pl/nouns/iksatywy.vue")
  }
]