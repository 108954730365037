import type { Config } from '~/locale/config.ts';

export const genders = ['masc', 'fem', 'neutr', 'nb'] as const;
export type Gender = typeof genders[number];

export const gendersWithNumerus = ['masc', 'fem', 'neutr', 'nb', 'mascPl', 'femPl', 'neutrPl', 'nbPl'] as const;

export const availableGenders = (config: Config): Gender[] => {
    return genders.filter((gender) => gender !== 'nb' || config.nouns.nonbinary);
};

export const iconNamesByGender: Record<Gender, string> = {
    masc: 'mars',
    fem: 'venus',
    neutr: 'neuter',
    nb: 'transgender-alt',
};
export const iconUnicodesByGender: Record<Gender, string> = {
    masc: '\uf222',
    fem: '\uf221',
    neutr: '\uf22c',
    nb: '\uf225',
};
export const longIdentifierByGender: Record<Gender, string> = {
    masc: 'masculine',
    fem: 'feminine',
    neutr: 'neuter',
    nb: 'nonbinary',
};
