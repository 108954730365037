import internationalEvents from '~/locale/_/calendar/events.ts';
import { Calendar } from '~/src/calendar/helpers.ts';
import type { Event } from '~/src/calendar/helpers.ts';

export const buildCalendar = (localEvents: Event[], baseUrl: string) => {
    return new Calendar(
        [...internationalEvents, ...localEvents], // TODO , ...namedays
        baseUrl,
        2021,
        2025,
    );
};
